import './App.css';
import './index.js';
import Form from './components/Form';
import React,{ useEffect, useState} from 'react';
import firebase from './util/firebase';
import TodoList from './components/TodoList';
import Firebase_apps_const from './util/Firebase_apps_const';

function App() {
 

  return (
    <div className="App">
      <header className="App-header">
        <p>Selekto aplikacionin</p>
        <Firebase_apps_const/>
      </header>
    </div>
  );
}

export default App;
