import React, {useState, Component} from "react";
import Select from 'react-select';
import '../App.css';
import firebase from '../util/firebase';
import { Alert } from 'react-alert'
import  ReactDOM  from "react-dom";
import { render } from "@testing-library/react";
//import anglisht_gjermanisht_firebase from "../util/anglisht_gjermanisht_firebase";

export default function Form(props){
    const [titulli, setTitulli] = useState(null);
    const [array_list, setArray_list] = React.useState([]);


    const [gj_a, setgj_a] = useState('');
    const [gj_a2, setgj_a2] = useState('');
    const [gj_h, setgj_h] = useState('');
    const [gj_h2, setgj_h2] = useState('');


    const [selectedOption, setSelectedOption] = useState('fjalit');


    const options_languages = [
        { value: "fjalit", label: 'Fjalit' },
        { value: "alfabeti", label: 'Alfabeti' },
        { value: 'gramatika', label: 'Gramatika' },
        { value: 'bisedat', label: 'Biseda' },
      ]


    const add_list = () => {
        const todo = {
            gj_a:gj_a,
            gj_a2:gj_a2,
            gj_h:gj_h,
            gj_h2:gj_h2
        };
        if( (gj_a === '' || !gj_a) &&  (gj_h === '' || !gj_h)){
            alert("Shkrunai nje fjal amtare dhe perktim");
            return;
        }
        array_list.push(todo);
         setgj_a('');
         setgj_h('');
         setgj_a2('');
         setgj_h2('');
        console.log("qekjo oo "+ JSON.stringify(array_list))
    }

     const perfundo_array = () => {
         
        if(selectedOption.value != "bisedat"){
            if(array_list.length <= 9)
            {
                alert("Duhet se paku 10 fjale ose fjali /n ju i keni ne list " + array_list.length);
                return;
            }
        }

        if(titulli === null){
            alert("Duhet te shkruani nje titull");
            return;
        }
        if(selectedOption.value === null)
        {
            alert("Selektoni nje kategori");
            return;
        }


       firebase.initializeApp(props.appConst);
        const todoRef = firebase.database().ref("admin").child(selectedOption.value).child(titulli);
        if (todoRef === null){
            //exist
        }else{
            setArray_list([]);
            setTitulli("");
            todoRef.set({
                Array: JSON.stringify(array_list),
                like: 0,
                titulli: titulli,
              }).then(() => {
                console.log("Data updated")
                window.location.reload();

              }).catch(e => console.log(e));

        }
     
    }
    
    let  show_list = (prop)=> <> <ul>A: {prop.gj_a}</ul>  
                                 <ul>H: {prop.gj_h} </ul> </>;
    if(selectedOption.value == 'bisedat'){
        show_list = (prop) =>   <>
       
                <ul>A: {prop.gj_a}</ul>  
                <ul>H: {prop.gj_h} </ul>
                
                <ul>A2: {prop.gj_a2} </ul>
                <ul>H2: {prop.gj_h2} </ul>
        </>;
    }

    function List() {
        
        const itemList = array_list.map((item) => (
         <ul>
            <li className="conversation_div" key={Date.now()}>
                {show_list(item)}
            </li>
          </ul>
          
        ));
      
        return (
            <div>
                <ol style={{ listStyleType: "none" }}>{itemList}</ol>
            </div>
            )
        
     
      }


    const fjala_amtare_onchange = (e) => {
        setgj_a(e.target.value);
    }
    const fjala_huaj_onchange = (e) => {
        setgj_h(e.target.value);
    }

    const fjala_amtare2_onchange = (e) => {
        setgj_a2(e.target.value);
    }
    const fjala_huaj2_onchange = (e) => {
        setgj_h2(e.target.value);
    }

    const setTitulli_ = (e) => {
        setTitulli(e.target.value);
    }


    let  show_conversation_field = <p></p>;

    if(selectedOption.value == 'bisedat'){
        show_conversation_field = 
         <>
            <input className="_input" placeholder="Fjala Amtare 2" type="text" onChange={fjala_amtare2_onchange} value={gj_a2} />
            <input className="_input" placeholder="Fjala Huaj 2" type="text" onChange={fjala_huaj2_onchange} value={gj_h2} />
         </> ;
    }

    let perfundo = <></>

    if(selectedOption.value != "bisedat"){
        if(array_list.length >= 10){
            perfundo =  <button className="perfundo_button" onClick={perfundo_array} >Perfundo</button>
        }
    }else{
        if(array_list.length >= 5){
            perfundo =  <button className="perfundo_button" onClick={perfundo_array} >Perfundo</button>
        }
    }



    return (
        <div className="div_container">

            <Select 
                className="select_languages" 
                options={options_languages} 
                defaultValue={selectedOption}
                onChange={setSelectedOption}
            />
            {<h3>Count: {array_list.length}</h3>}


            <input placeholder="Shkruaje Titullin" type="text" onChange={setTitulli_} value={titulli} />


            <div className="inputs_form">
               <input className="_input" placeholder="Fjala Amtare" type="text" onChange={fjala_amtare_onchange} value={gj_a} />
               <input className="_input" placeholder="Fjala Huaj" type="text" onChange={fjala_huaj_onchange} value={gj_h} />
               {show_conversation_field}
               
            </div>

            <div className="inputs_form">
                <button className="add_button" onClick={add_list}  >Add</button>
                {perfundo}
            </div>


            <List/>

        </div>
    )


}