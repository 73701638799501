
import firebase from 'firebase';

//Anglisht Spanjollisht
const anglisht_spanjollisht = {
    apiKey: "AIzaSyA3hTJx-0Qk1Cg3lZrOhOK9Zkh3Mb3yRFg",
    authDomain: "learn-spanish-android.firebaseapp.com",
    databaseURL: "https://learn-spanish-android.firebaseio.com",
    projectId: "learn-spanish-android",
    storageBucket: "learn-spanish-android.appspot.com",
    messagingSenderId: "577225239376",
    appId: "1:577225239376:web:d5f0087c3890af7ee31a55",
    measurementId: "G-CQ1VYYMKWP"
  };

//Anglisht Gjermanisht
const anglisht_gjermanisht = {
  apiKey: "AIzaSyDVqWYKr3N9kDDQqXmSGzsK9qaPk_yelro",
  authDomain: "learn-german-android.firebaseapp.com",
  databaseURL: "https://learn-german-android.firebaseio.com",
  projectId: "learn-german-android",
  storageBucket: "learn-german-android.appspot.com",
  messagingSenderId: "650791252797",
  appId: "1:650791252797:web:a78e1a23569df905a24b54",
  measurementId: "G-PTYC5HWV5L"
};

//Kroacisht Gjermanisht
const kroacisht_gjermanisht = {
  apiKey: "AIzaSyC9rGCfaYZPQbYlceUb_R0d3423c5ojrTc",
  authDomain: "learncroatioangerman.firebaseapp.com",
  databaseURL: "https://learncroatioangerman.firebaseio.com",
  projectId: "learncroatioangerman",
  storageBucket: "learncroatioangerman.appspot.com",
  messagingSenderId: "301158573138",
  appId: "1:301158573138:web:0f609adeecdaa3bff1ab8b",
  measurementId: "G-QZPM8Y8CFP"
};
//Kroacisht Frengjisht
const kroacisht_frengjisht = {
  apiKey: "AIzaSyBtpzM3jB9_G5HyENBo2Z0H65_R9d-L_B8",
  authDomain: "kroacisht-frangjisht.firebaseapp.com",
  databaseURL: "https://kroacisht-frangjisht-default-rtdb.firebaseio.com",
  projectId: "kroacisht-frangjisht",
  storageBucket: "kroacisht-frangjisht.appspot.com",
  messagingSenderId: "220791244295",
  appId: "1:220791244295:web:a0fe158af364c4400b8ca2",
  measurementId: "G-FYXEH3CWMK"
};
//Kroacisht Anglisht
const kroacisht_anglisht = {
  apiKey: "AIzaSyDjoZylHsm4P2-oyXCpzPZ_11XsdX8pvyo",
  authDomain: "uciti-engleski.firebaseapp.com",
  databaseURL: "https://uciti-engleski.firebaseio.com",
  projectId: "uciti-engleski",
  storageBucket: "uciti-engleski.appspot.com",
  messagingSenderId: "235130877379",
  appId: "1:235130877379:web:a18c7ea743bd430821f976",
  measurementId: "G-4GE0R1WECC"
};
//Arabisht Frengjisht
const arabisht_frengjisht = {
  apiKey: "AIzaSyBN5Gh2yQQa9Kb1wDp2YDn-3OhSRocqlgI",
  authDomain: "arabisht-frangjisht.firebaseapp.com",
  databaseURL: "https://arabisht-frangjisht.firebaseio.com",
  projectId: "arabisht-frangjisht",
  storageBucket: "arabisht-frangjisht.appspot.com",
  messagingSenderId: "78844827118",
  appId: "1:78844827118:web:f8833f55959ad8a4756aff",
  measurementId: "G-CPSRVH5YPZ"
};
// Arabisht Gjermanisht
const arabisht_gjermanisht = {
  apiKey: "AIzaSyC0PB2xMiG5BDlH5XtRmn77LGheCyW5Zak",
  authDomain: "lerne-deutsch.firebaseapp.com",
  databaseURL: "https://lerne-deutsch.firebaseio.com",
  projectId: "lerne-deutsch",
  storageBucket: "lerne-deutsch.appspot.com",
  messagingSenderId: "42191991968",
  appId: "1:42191991968:web:c410bc57e160c36d1cc5c5",
  measurementId: "G-H87T0VH9D3"
};
//Arabisht Suedisht
const arabisht_suedisht = {
  apiKey: "AIzaSyA0LgK0K7No49N_2q7iFZOcfXA-q3_N2i8",
  authDomain: "arabisht-suedisht.firebaseapp.com",
  databaseURL: "https://arabisht-suedisht.firebaseio.com",
  projectId: "arabisht-suedisht",
  storageBucket: "arabisht-suedisht.appspot.com",
  messagingSenderId: "194414766184",
  appId: "1:194414766184:web:de1a724fdf0cf9db3eb3dd",
  measurementId: "G-FSGK2STRT6"
};

//Bullgarisht Anglisht
const Bullgarisht_Anglisht = {
  apiKey: "AIzaSyBFaxurP0fKRb2281Veywip6UI3JbPd2GI",
  authDomain: "bulgarian-english.firebaseapp.com",
  databaseURL: "https://bulgarian-english-default-rtdb.firebaseio.com",
  projectId: "bulgarian-english",
  storageBucket: "bulgarian-english.appspot.com",
  messagingSenderId: "344464279181",
  appId: "1:344464279181:web:07130897d78b07417344c4",
  measurementId: "G-65T2HRY90K"
};
//Bullgarisht Gjermanisht
const Bullgarian_German = {
  apiKey: "AIzaSyA8svib2sZp0ds9HAEClftyVnPZZVi9Yvc",
  authDomain: "bulgarian-german-android.firebaseapp.com",
  databaseURL: "https://bulgarian-german-android.firebaseio.com",
  projectId: "bulgarian-german-android",
  storageBucket: "bulgarian-german-android.appspot.com",
  messagingSenderId: "390730066087",
  appId: "1:390730066087:web:981d986340d64cbf7f1382",
  measurementId: "G-DFTZZ5PGYM"
};

//Gjermanisht Frengjisht
const Gjermanisht_frengjisht = {
  apiKey: "AIzaSyD6CRhCxAhQTMdijRW7Rdcqxp9_dy2trFA",
  authDomain: "franzosischlernen-d8db6.firebaseapp.com",
  databaseURL: "https://franzosischlernen-d8db6.firebaseio.com",
  projectId: "franzosischlernen-d8db6",
  storageBucket: "franzosischlernen-d8db6.appspot.com",
  messagingSenderId: "928445622643",
  appId: "1:928445622643:web:93ddf140286db76be2fd06",
  measurementId: "G-33P35DXZE0"
};
// Gjermanisht Holandisht
const Gjermanisht_holandisht = {
  apiKey: "AIzaSyBXA99VIt29XQM2TkY2Uah62s4L9bu5Vfs",
  authDomain: "lerne-niederlandisch.firebaseapp.com",
  databaseURL: "https://lerne-niederlandisch-default-rtdb.firebaseio.com",
  projectId: "lerne-niederlandisch",
  storageBucket: "lerne-niederlandisch.appspot.com",
  messagingSenderId: "963467368427",
  appId: "1:963467368427:web:883033eddbf819fbf5ebae",
  measurementId: "G-FG55RV66LT"
};
//Gjermanisht Anglisht
const Gjermanisht_Anglisht = {
  apiKey: "AIzaSyCTqdhAH3EkrOOAwTLSYDyaaTyisYvrEKg",
  authDomain: "lerne-englisch-android.firebaseapp.com",
  databaseURL: "https://lerne-englisch-android.firebaseio.com",
  projectId: "lerne-englisch-android",
  storageBucket: "lerne-englisch-android.appspot.com",
  messagingSenderId: "771882757746",
  appId: "1:771882757746:web:a4453bab94242d56f09ceb",
  measurementId: "G-DC3QWBVFNC"
};

//Rumanisht Anglisht
const Rumanisht_anglisht = {
  apiKey: "AIzaSyDDLA5D9RsSc_gCFoEXxJSYPYnoq7qIuh0",
  authDomain: "invata-engleza.firebaseapp.com",
  databaseURL: "https://invata-engleza.firebaseio.com",
  projectId: "invata-engleza",
  storageBucket: "invata-engleza.appspot.com",
  messagingSenderId: "435402081912",
  appId: "1:435402081912:web:c27c21776b22921e9dcf8d",
  measurementId: "G-9QP5EE98E7"
};
//Rumanisht Frengjisht
const Rumanisht_frengjisht = {
  apiKey: "AIzaSyAcDftK-cwv8u2AsxirdoAbY9FG-wIbBk4",
  authDomain: "invata-franceza.firebaseapp.com",
  databaseURL: "https://invata-franceza.firebaseio.com",
  projectId: "invata-franceza",
  storageBucket: "invata-franceza.appspot.com",
  messagingSenderId: "1065232596298",
  appId: "1:1065232596298:web:3b16fa9b681a11ee58d5b3",
  measurementId: "G-1L25SGL2EZ"
};
//Rumanisht Gjermanisht
const Rumanisht_gjermanisht = {
  apiKey: "AIzaSyDgYZarD3t9amhpPrI50_2MIr11mmuJBVg",
  authDomain: "invata-germana-android.firebaseapp.com",
  databaseURL: "https://invata-germana-android.firebaseio.com",
  projectId: "invata-germana-android",
  storageBucket: "invata-germana-android.appspot.com",
  messagingSenderId: "75679621375",
  appId: "1:75679621375:web:5316808edfbb97db30ecca",
  measurementId: "G-6DT4NRB3JM"
};
//Rumanisht Italisht
const Rumanisht_italisht = {
  apiKey: "AIzaSyCg0UpiDonbrifvW9tdXnmzFN2cp3vew3k",
  authDomain: "invata-italiana-android.firebaseapp.com",
  databaseURL: "https://invata-italiana-android.firebaseio.com",
  projectId: "invata-italiana-android",
  storageBucket: "invata-italiana-android.appspot.com",
  messagingSenderId: "598121437713",
  appId: "1:598121437713:web:fa9c741b3fdf79d6876fb0",
  measurementId: "G-8M9EFHFWNK"
};
//Rumanisht Spanjollisht
const Rumanisht_spanjollisht = {
  apiKey: "AIzaSyApYjsAPnEU1S66ks875rlklQ5-M4s3Qy0",
  authDomain: "invata-spaniola-android.firebaseapp.com",
  databaseURL: "https://invata-spaniola-android.firebaseio.com",
  projectId: "invata-spaniola-android",
  storageBucket: "invata-spaniola-android.appspot.com",
  messagingSenderId: "581399525978",
  appId: "1:581399525978:web:005ebd7f9ade040a93b663",
  measurementId: "G-8BD63HDH3L"
};

//Meso Anglisht
const meso_anglisht = {
  apiKey: "AIzaSyCJoh9b9B3KT9ExXuZG8AHMHP03Jk8_9tU",
  authDomain: "learn-english-6c4d0.firebaseapp.com",
  databaseURL: "https://learn-english-6c4d0.firebaseio.com",
  projectId: "learn-english-6c4d0",
  storageBucket: "learn-english-6c4d0.appspot.com",
  messagingSenderId: "510539016596",
  appId: "1:510539016596:web:dfd04f7d5025e5305c4781",
  measurementId: "G-B2RN1Y101X"
};
//Meso Gjermanisht
const meso_gjermanisht = {
  apiKey: "AIzaSyCGUUaDh8AUUnecB8JlO3F9UqUN6Qwdpik",
  authDomain: "meso-gjermanisht.firebaseapp.com",
  databaseURL: "https://meso-gjermanisht.firebaseio.com",
  projectId: "meso-gjermanisht",
  storageBucket: "meso-gjermanisht.appspot.com",
  messagingSenderId: "391991968488",
  appId: "1:391991968488:web:3364ecfd346d154e4b3f5d",
  measurementId: "G-MKXDH7GDSJ"
};
//Meso Frengjisht
const meso_frengjisht = {
  apiKey: "AIzaSyAwx_WNnkeS8XWUJF69ICyyuKfjZUOfmxY",
  authDomain: "meso-frangjisht.firebaseapp.com",
  databaseURL: "https://meso-frangjisht.firebaseio.com",
  projectId: "meso-frangjisht",
  storageBucket: "meso-frangjisht.appspot.com",
  messagingSenderId: "400050343841",
  appId: "1:400050343841:web:fdba18964aedb3bfa045e0",
  measurementId: "G-T4LV08XTLN"
};
//Meso Italisht
const meso_italisht = {
  apiKey: "AIzaSyATde6ds4Jc8cA3_ut6cMGrkAk0AiwVJkc",
  authDomain: "meso-italisht.firebaseapp.com",
  databaseURL: "https://meso-italisht.firebaseio.com",
  projectId: "meso-italisht",
  storageBucket: "meso-italisht.appspot.com",
  messagingSenderId: "30754833875",
  appId: "1:30754833875:web:e90591586df4c2064d1b26",
  measurementId: "G-N45WRYWBG2"
};
//Meso Greqisht
const meso_greqisht = {
  apiKey: "AIzaSyBfi0rx2mkRBG0MC_-9RRNaKeUtMo8Lrpw",
  authDomain: "meso-greqisht.firebaseapp.com",
  databaseURL: "https://meso-greqisht.firebaseio.com",
  projectId: "meso-greqisht",
  storageBucket: "meso-greqisht.appspot.com",
  messagingSenderId: "1003005817116",
  appId: "1:1003005817116:web:9c9583957ec2da82fbcad9",
  measurementId: "G-5PF6MQC2VT"
};

//Polonisht Anglisht
const polonisht_anglisht = {
  apiKey: "AIzaSyBAqzHOBn11fcpb5NxkwfOj6A4bPx77WGI",
  authDomain: "polonisht-anglisht-android.firebaseapp.com",
  databaseURL: "https://polonisht-anglisht-android.firebaseio.com",
  projectId: "polonisht-anglisht-android",
  storageBucket: "polonisht-anglisht-android.appspot.com",
  messagingSenderId: "949957954514",
  appId: "1:949957954514:web:8387940207035612ca960b",
  measurementId: "G-8BB1ZJ53NG"
};
//Polonisht Gjermanisht
const polonisht_gjermanisht = {
  apiKey: "AIzaSyCFsV8zqzcTyzNA_NZZMNcGovHTvntar-E",
  authDomain: "naucz-sie-niemieckiego-android.firebaseapp.com",
  databaseURL: "https://naucz-sie-niemieckiego-android.firebaseio.com",
  projectId: "naucz-sie-niemieckiego-android",
  storageBucket: "naucz-sie-niemieckiego-android.appspot.com",
  messagingSenderId: "886195239538",
  appId: "1:886195239538:web:8ae0f60b67fc08763fe0eb",
  measurementId: "G-3EM6D0G5DV"
};

//Turqisht Anglisht
const turqisht_anglisht = {
  apiKey: "AIzaSyDR-46sQg1vxGx-KDr0Ir_-JPxSYmI6lCc",
  authDomain: "learnturkishenglish.firebaseapp.com",
  databaseURL: "https://learnturkishenglish.firebaseio.com",
  projectId: "learnturkishenglish",
  storageBucket: "learnturkishenglish.appspot.com",
  messagingSenderId: "1052861630413",
  appId: "1:1052861630413:web:8389698df82afdd4df6684",
  measurementId: "G-03B3PF8PYW"
};

//Turqisht Gjermanisht
const turqisht_gjermanisht = {
  apiKey: "AIzaSyBQRhXA1dHw4teeCH9foEKKkl5U__a8meM",
  authDomain: "turkish-german.firebaseapp.com",
  databaseURL: "https://turkish-german-default-rtdb.firebaseio.com",
  projectId: "turkish-german",
  storageBucket: "turkish-german.appspot.com",
  messagingSenderId: "952412506232",
  appId: "1:952412506232:web:b53db28149f78f4e6f058d",
  measurementId: "G-XY03W36RGY"
};
//Turqisht Frengjisht
const turqisht_frengjisht = {
  apiKey: "AIzaSyDgxhhT8nG2-BVtels4C1tDPiXrS-v-X68",
  authDomain: "turkish-french.firebaseapp.com",
  databaseURL: "https://turkish-french-default-rtdb.firebaseio.com",
  projectId: "turkish-french",
  storageBucket: "turkish-french.appspot.com",
  messagingSenderId: "792920755639",
  appId: "1:792920755639:web:c3f6698bf10f5aaddeac32",
  measurementId: "G-3X461HCLKT"
};

//Ukrainikisht Anglisht
const ukranian_anglisht = {
  apiKey: "AIzaSyCgLlA1MnIJzhbup2Exn_nblCaCml0sgcc",
  authDomain: "ukrainisht-anglisht-android.firebaseapp.com",
  databaseURL: "https://ukrainisht-anglisht-android.firebaseio.com",
  projectId: "ukrainisht-anglisht-android",
  storageBucket: "ukrainisht-anglisht-android.appspot.com",
  messagingSenderId: "990480007643",
  appId: "1:990480007643:web:ce601a751e383c95b1e7fe",
  measurementId: "G-1WN7TXQ72F"
};

//Ukrainikisht Gjermanisht
const ukranian_gjerman = {
  apiKey: "AIzaSyCHbQ57nvyX03PcKNsqO4yhDYaIo4aU5Ls",
  authDomain: "ukranian-german.firebaseapp.com",
  databaseURL: "https://ukranian-german-default-rtdb.firebaseio.com",
  projectId: "ukranian-german",
  storageBucket: "ukranian-german.appspot.com",
  messagingSenderId: "367943225707",
  appId: "1:367943225707:web:ace7d8af3532de5fd5fc2e",
  measurementId: "G-KNGT96RG49"
};

//Bosnian German
const bosnian_german = {
  apiKey: "AIzaSyCJH8g0LMpe7cTiDaLhX1hIsfcPRXnWF_Y",
  authDomain: "bosnian-german-android.firebaseapp.com",
  databaseURL: "https://bosnian-german-android.firebaseio.com",
  projectId: "bosnian-german-android",
  storageBucket: "bosnian-german-android.appspot.com",
  messagingSenderId: "1005419647878",
  appId: "1:1005419647878:web:f8fb3db6b1f88b862a9207",
  measurementId: "G-PSH9J8K2CW"
};

//Hungarisht Gjermanisht
const hungarisht_gjermanisht = {
  apiKey: "AIzaSyDeeKznZN4W9Y8ExyBJarcObJw6xsyB3LM",
  authDomain: "hungarisht-gjermanisht-android.firebaseapp.com",
  databaseURL: "https://hungarisht-gjermanisht-android.firebaseio.com",
  projectId: "hungarisht-gjermanisht-android",
  storageBucket: "hungarisht-gjermanisht-android.appspot.com",
  messagingSenderId: "365155796934",
  appId: "1:365155796934:web:7614e919a564946b9e14c7",
  measurementId: "G-F5FJP5Z4CW"
};

//Korean English
const korean_english = {
  apiKey: "AIzaSyDg_6U_s_NcrIDbF9uMeI8y4BCA6D7f_LU",
  authDomain: "korean-english.firebaseapp.com",
  databaseURL: "https://korean-english-default-rtdb.firebaseio.com",
  projectId: "korean-english",
  storageBucket: "korean-english.appspot.com",
  messagingSenderId: "363107823102",
  appId: "1:363107823102:web:51e9a565d8eb5a329164af",
  measurementId: "G-531QLCL7RX"
};
//Portugalisht Frengjisht
const portugalisht_frengjisht = {
  apiKey: "AIzaSyAGZmRslHEYi-WVk7G4sFsGNP1Yc9q7JlM",
  authDomain: "portugalisht-frengjisht.firebaseapp.com",
  databaseURL: "https://portugalisht-frengjisht-default-rtdb.firebaseio.com",
  projectId: "portugalisht-frengjisht",
  storageBucket: "portugalisht-frengjisht.appspot.com",
  messagingSenderId: "515040960453",
  appId: "1:515040960453:web:665266622d0949abe9f5ac",
  measurementId: "G-T0GB3MJWGW"
};
//Rusisht Gjermanisht
const rusisht_gjermanisht = {
  apiKey: "AIzaSyA5fNZZTwZcyhyBEjvjPAOI1t3GffpkJi0",
  authDomain: "rusisht-gjermanisht.firebaseapp.com",
  databaseURL: "https://rusisht-gjermanisht-default-rtdb.firebaseio.com",
  projectId: "rusisht-gjermanisht",
  storageBucket: "rusisht-gjermanisht.appspot.com",
  messagingSenderId: "504482851606",
  appId: "1:504482851606:web:0abba0a48dda12e9f8fe37",
  measurementId: "G-ME9DDEFJ1Q"
};

  //Ukranian Spanish
  const ukranian_spanish = {
    apiKey: "AIzaSyApW4obhIA9zsm0kHmJkPX61CVcisTJjHs",
    authDomain: "ukranian-spanish.firebaseapp.com",
    databaseURL: "https://ukranian-spanish-default-rtdb.firebaseio.com",
    projectId: "ukranian-spanish",
    storageBucket: "ukranian-spanish.appspot.com",
    messagingSenderId: "522534942259",
    appId: "1:522534942259:web:e958a16f8ca972fe551546",
    measurementId: "G-ME9DDEFJ1Q"
  };

    //Georgian English
    const georgian_english = {
      apiKey: "AIzaSyBTm_cHrOAoNHGWwh362n-RwLlVaWtI218",
      authDomain: "georgian-english.firebaseapp.com",
      databaseURL: "https://georgian-english-default-rtdb.firebaseio.com",
      projectId: "georgian-english",
      storageBucket: "georgian-english.appspot.com",
      messagingSenderId: "590337916243",
      appId: "1:590337916243:web:655b9d6c40b4aa9f4775a6",
      measurementId: "G-5H8XNK4KL5"
    };


  

  // firebase.initializeApp(anglisht_spanjollisht);

  export default firebase;